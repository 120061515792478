import React, { Component } from 'react';
import Layout from '../components/layout/layout';
import { connect } from 'react-redux';
import { START_LOADING, STOP_LOADING } from '../actions/actionTypes';
import { navigate } from 'gatsby';

class Loader3DS extends Component {
  componentDidMount() {
    const {
      startLoading,
      location: { search },
    } = this.props;

    startLoading();
    if (!search) {
      navigate('/');
    } else {
      let paramsArr = search.substr(1).split('&');
      paramsArr.map((data, index) => {
        switch (index) {
          case 0:
            if (this._checkParamExistence(data, 'client_secret')) break;
            else return;
          case 1:
            if (this._checkParamExistence(data, 'livemode')) break;
            else return;
          case 2:
            if (this._checkParamExistence(data, 'source')) break;
            else return;
          default:
            break;
        }
      });
    }
  }

  componentWillUnmount() {
    const { stopLoading } = this.props;

    stopLoading();
  }

  _checkParamExistence(param, match) {
    if (param.split('=')[0] === match && param.split('=')[1].length > 0) {
      return true;
    } else {
      navigate('/');
      return false;
    }
  }

  render() {
    const { data } = this.props;

    return (
      <Layout
        title={data.wordings.fr.summary.page_title}
        description={data.wordings.fr.summary.description}
      />
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    startLoading: () => {
      dispatch({ type: START_LOADING });
    },
    stopLoading: () => {
      dispatch({ type: STOP_LOADING });
    },
  };
};

export default connect(
  null,
  mapDispatchToProps
)(Loader3DS);

export const query = graphql`
  query Loader3DS {
    wordings {
      fr {
        summary {
          page_title
          description
        }
      }
    }
  }
`;
